#img-div{
    display: flex;
    z-index: 10;
}

.header-div {
    margin-top: 20px;
}


.header-title{
    position: relative;
    display: flex;
    margin: auto;

    width: 100%;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 730px) {
    .header-title {
        flex-direction: row;
    }
}

@media screen and (max-width: 730px) {
    .header-title {
        flex-direction: column;
    }
    
}
.news-content {
    font-size: 15px;
}
.news {
    right: 0;
    width: 100%;
    margin: auto;
    overflow-y: hidden;
    padding: 10px;
    /* border: 2px dashed var(--light-yellow); */
    background-color: var(--sub);
    color: black;
    font-weight: 100;
}

#img-div > img {
    border-radius: 300px;
    width: 300px;
    height: 300px;
    margin: 0 auto;
}

#description {
    margin: auto;
    height: fit-content;
    font-size: 18px;
    /* font-family: 'Noto Serif KR', serif; */
    font-weight:lighter;
    border-top: 3px solid var(--sub);  
    max-width: 600px;
    padding: 10px 10px;
    text-align: justify;
}

.iam {
    color: var(--main);
    font-size: 30px;
    font-weight: bolder;
    font-family: 'Noto Serif KR', serif;
}

#body-div {    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.date{
    font-style: italic;
}

.news > #title {
    font-weight: bold;
}

.profile {
    position: relative;
}
.footer-container{
    width: 100%;
    display: flex;
}
.div1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
}

.div1 > a {
    width: 20px;
    height: 20px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bolder;
    color: var(--main);
    border-radius: 20px;
    padding: 5px;
    background-color: var(--sub);
}

.div1 > a:hover {
    color: var(--sub);
    background-color: var(--main);
}

.div1 > a > p {
    margin: 0;
    text-align: center;
    transform: translateY(-2px);
}