#education {
  width: 100%;
}
#education-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.education-item {
    padding-bottom: 20px;
}

.education-item, .education-description {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}

.education-year {
    width: 150px;
    font-style: italic;
    /* color: var(--yellow); */
    /* text-shadow: 1px 1px 0px var(--dark-green); */
}