#funproject {
    width: 100%;
}

.wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    z-index: 0;
    justify-content: center;
    gap: 5px;
}

.content {
    width: 300px;
    height: 150px;
    position: relative;
    border-radius: 10px;
    border: 3px solid var(--main);
    cursor: pointer;
}


.card-title {
    width: 50%;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(0%, -50%);
    text-align: center;
    color: #000;
    text-shadow: 1px 1px 1px #fff;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.card-title h3{
    font-size: 18px;
    font-weight: bold;
}

.card-title p{
    font-size: 12px;
}

.card-title .hashtags {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;   
    padding: 5px;
    gap: 5px;
}

.card-title .hashtag {
    text-shadow: none;
    font-style: italic;
    padding: 0 10px;
    border-radius: 10px;
    margin: 0;
}

.main {
    background-color: var(--main);
    color: white;
}

.sub {
    background-color: var(--sub);
    color: var(--main);
}

.other {
    background-color: white;
    color: var(--main);
    border: 2px solid var(--main);
}

.content:hover p{
    visibility: visible;
    transition-duration: 0.2s;
}

.content img {
    width: 50%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
}

.content:hover img{
    opacity: 1;
    transition: all 0.5s;
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    padding: 10px;
    flex-wrap: scroll;
}

.modal > .close {
    padding: 5px 9px;
    border-radius: 20px;
    border: none;
    background-color: var(--main);
    color: var(--light-yellow);
    position: absolute;
    top: 5px;
    right: 5px;
}

.close:hover {
    cursor: pointer;
    background-color: var(--dark-green);
    color: var(--light-yellow);
}

.modal-content{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}


.modal-description{
    font-style: italic;
    padding: 0;
    margin: 0;
}


.modal-category {
    margin: 0;
    padding: 5px;
    font-size: 12px;
    border: 2px solid var(--yellow);
    color:var(--yellow);
    border-radius: 100px;
}

.modal-awards {
    margin: 0;
    padding: 5px;
    font-size: 12px;
    background-color: var(--light-yellow);
    color:var(--main);
    border-radius: 100px;
}

.modal-hashtag{
    font-size: 12px;
    font-style: italic;
}

.modal-img{
    width: fit-content;
    height: 200px;
}

.modal > video{
    width: 100%;
    height: 100%;
}
.modal > iframe{
    width: 100%;
}

h2, h3{
    margin:0;
}

.modal-github {
    width: 30px;
}