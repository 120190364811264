.menu-div {
  display: flex;
  position: relative; 
  /* background-image: linear-gradient(90deg, var(--light-yellow), var(--light-green)); */
 
  background-color: var(--main);
  width: 100%;
  justify-content: start;
  position: fixed;
  height: 50px;
  z-index: 5;

  align-items: center;
}

.menuItem-div {
  width: 150px;
  padding: 0px 10px;
  cursor: pointer;
  color: var(--dark-green);
  font-weight: bold;
  text-align: center;
}

.menuItem-div:hover > p {
  text-shadow: 2px 2px 1px var(--yellow);
}
.menu-trigger img{
  position: absolute;
  top: 12.5px;
  left: 20px;
  height: 25px;
  width: 25px;
  overflow: hidden;
  cursor: pointer;
}

.menu-trigger.active img{
  transition: 0.5s ease;

}

.dropdown-menu{
  position: absolute;
  top: 50px;
  left: 0px;
  background-color: var(--light-yellow);
  border-radius: var(--border-radius);
  padding: 0px 10px;
}

.dropdown-menu::before{
  content: '';
  position: absolute;
  top: -5px;
  left: 20px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

.dropdown-menu.active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 0.5s ease;
}

.dropdown-menu.inactive{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}


.dropdown-menu ul {
  
  margin: 0px;
  padding: 0px;
}
.dropdown-menu ul li{
  padding: 10px  0px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menu ul li:hover a{
  color:var(--yellow);
  text-shadow: 1px 1px 1px var(--dark-green);
  cursor: pointer;
}

.dropdown-menu ul li:hover img{
  opacity: 1;
  cursor: pointer;
}

.dropdownItem{
  display: flex;
  margin: 10px auto;
}

.dropdownItem img{
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a{
  max-width: 100px;
  margin-left: 10px;
  transition: var(--speed);
}

#myname {
  text-decoration: none;
  padding-right: 10px;
  font-weight: bolder;
  color: white;
  font-size: 18px;
  margin: 10px;
  text-shadow: 1px 1px 1px var(--dark-green);
}