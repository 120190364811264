@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;500;600&family=Noto+Serif+KR:wght@300;400;500&family=Roboto+Slab:wght@300;400;500&display=swap');

html, body, .root{
    width: 100%;
}
body {
    font-family: 'Roboto Slab', serif;
    font-family: 'Noto Sans KR', sans-serif;
}
.App {
    padding: 0px;
    display: flex;
    width: 100%;
}

.Body {
    display: block;
    width: 100%;
    margin: 0 auto;
}

.main-content {
    display:block;
    width: 80%;
    margin: 0 auto;
    padding-top: 60px;
}