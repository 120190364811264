#publication {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.publication-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}



.publication-div{
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

@media(width <= 1024px) {
    .publication-div{
        flex-direction: column;
    }
}

@media(width >= 1024px) {
    .publication-div{
        flex-direction: row;

    }
}

.publication-div h3{
    margin: 10px 0px;
}

.publication-div p{
    margin: 2px 0px;
}

.publication-div > .publication-img-div{
    width: 250px;
    height: 150px;
    border-radius: 0%;
}

/* .publication-content-div {
    width: calc(100% - 250px);
} */
.publication-img-div > img{
    width: 250px;
}

.author-div {
    display: flex;
    flex-wrap: wrap;
}

.isMe {
    color: var(--main);
    background-color: var(--sub);
}

.url-div > a {
    color: var(--sub);
    background-color: var(--main);
    text-decoration: none;
    padding: 0px 5px;
    border-radius: 20px;  
    font-size: small;
    cursor: pointer;
}

.url-div > a:hover {
    color: white;
    background-color: var(--sub);
}

.url-div {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.where {
    font-style: italic;
    font-size: 12px;
}

.award {
    display: flex;
    gap: 10px;
    padding: 10px;
    color: var(--main);
}

.award > svg{
    width: fit-content;
    border-radius: 50px;
    font-size: 20px;

}

.award > p {
    margin: 0;
    padding: 0;
    font-size: 14px;
}