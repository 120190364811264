:root {
    --main: #6482AD;
    --sub: #E2DAD6;

    width: 99%;
}

.color-variant {
    filter: brightness(0.8);
    color: var(--color-variant);
}


body{
    background-color: white;
}
