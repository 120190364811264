.page-div {
    padding-top: 80px;
    flex-direction: column;
    gap: 10px;

    width: 80%;
    margin-left: auto;
    align-items: start;
    justify-content: center;
}

.page-img-div > img {
    width: 100%;
    max-width: 400px;
}