.footer-div {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.div2 {
    text-align: center;
    background-color: var(--main);
    /* background-image: linear-gradient(90deg, var(--light-yellow), var(--light-green)); */
    color: var(--sub);
    padding: 10px 0px;
}